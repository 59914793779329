.dashboard {
  background-color: #f1faff;
  display: flex;
  flex-direction: column;
  &_topbar {
    height: 6rem;

    z-index: 100;
    .top-bar {
      height: 100%;
      width: 100%;
      background-color: #11283c;
      box-shadow: 0rem 0.4rem 4.5rem rgba(0, 0, 0, 0.35);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .logo {
        text-decoration: none;
        margin-left: 3rem;
        font-family: "Newake";
        font-style: normal;
        font-weight: bold;
        font-size: 3.2rem;
        color: #fff;
      }
      .power-icon {
        margin-right: 3rem;
        cursor: pointer;
      }
    }
  }
  &_bottom {
    height: 90.1rem;
    overflow-y: scroll;

    display: flex;

    &_navbar {
      flex: 0 0 14%;

      .sidebar {
        width: 14%;
        position: fixed;
        padding-top: 34px;
        background-color: #11283c;
        box-shadow: 0rem 0.4rem 4.5rem rgba(0, 0, 0, 0.35);
        z-index: 20;

        height: 100%;

        display: flex;
        flex-direction: column;

        .side-nav {
          font-family: "Pangram";
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          padding: 0;
          &_items {
            position: relative;
            padding: 1.5rem 0 1.5rem 0;
            list-style-type: none;
            text-align: left;
            padding-left: 3rem;
            cursor: pointer;
            color: #fff;

            &:not(:last-child) {
              margin-bottom: 0.4rem;
            }
          }

          &_items::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            width: 0.2rem;
            height: 100%;
            background: rgba(103, 179, 249, 0.2);
            border-left: solid 0.2rem #fff;
            transform: scaleY(0);

            transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
          }

          &_items:hover::before {
            transform: scaleY(1);
            width: 100%;
          }
          &_link:link,
          &_link:visited {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }

    &_content {
      width: 100%;
    }
  }
}

// .dashboard {
//   width: 100%;
//   height: 100vh;
//   background-color: #f1faff;
//   display: flex;
//   flex-direction: column;
// }

// .top-bar {
//   height: 80px;
//   width: 100%;
//   background: #ffffff;
//   box-shadow: 15px 4px 45px rgba(17, 40, 60, 0.1);
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: center;

//   z-index: 100;
//   .logo {
//     text-decoration: none;
//     margin-left: 30px;
//     font-family: "Newake";
//     font-style: normal;
//     font-weight: bold;
//     font-size: 32px;
//   }
// }
// .container {
//   z-index: 1;
//   display: flex;
//   flex-direction: row;
// }

// .sidebar {
//   padding-top: 34px;
//   background-color: #ffffff;
//   box-shadow: 15px 4px 45px rgba(17, 40, 60, 0.1);
//   z-index: 20;
//   flex: 0 0 16%;
//   height: 860px;

//   display: flex;
//   flex-direction: column;

//   .side-nav {
//     font-family: "Pangram";
//     font-style: normal;
//     font-weight: 400;
//     font-size: 18px;
//     padding: 0px;
//     &_items {
//       position: relative;
//       padding: 15px 0 15px 0;
//       list-style-type: none;
//       text-align: left;
//       padding-left: 30px;
//       cursor: pointer;

//       &:not(:last-child) {
//         margin-bottom: 4px;
//       }
//     }

//     &_items::before {
//       content: "";
//       position: absolute;
//       top: 0px;
//       left: 0px;
//       width: 2px;
//       height: 100%;
//       background: rgba(103, 179, 249, 0.2);
//       border-left: solid 2px #11283c;
//       transform: scaleY(0);
//       transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
//     }

//     &_items:hover::before {
//       transform: scaleY(1);
//       width: 305px;
//     }
//     &_link:link,
//     &_link:visited {
//       text-decoration: none;
//       color: #333;
//     }
//   }
// }

// .main-content {
//   background-color: rgba(174, 210, 231, 0.3);
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// }
