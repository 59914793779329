.signin-popup {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);

  .signin-warning-text {
    color: #ffffff;
    font-size: 3.2rem;
    margin-bottom: 3rem;
    font-weight: 300;
  }

  .signin-card {
    height: 62rem;
    width: 68rem;
    background: #ffffff;

    border-radius: 3rem;
    padding: 4rem 3rem;

    .signin-text {
      margin: 3rem;
      font-family: "Newake", sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 7rem;
      color: #11283c;
    }

    .signin-form {
      margin: 1.5rem;
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;
      &-content {
        margin: 1.5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 3rem;
        align-items: center;

        &-item {
          outline: none;
          margin-bottom: 3rem;
          font-family: "Pangram";
          font-style: normal;
          font-weight: 400;
          font-size: 2.2rem;
          line-height: 2.9rem;
          color: #1b1c1e;
          width: 51.7rem;
          height: 6.8rem;
          border: 0;
          border-bottom: 0.1rem solid #1b1c1e;
          transition: all 0.1s ease-out;

          background-color: transparent;
          &::-webkit-input-placeholder {
            color: #a1a1a1;
          }
        }

        &-item:focus {
          transform: translateY(-0.1rem), scale(1.01);
          outline: none;
          border-bottom: 0.2rem solid #1b1c1e;
          filter: drop-shadow(0 2.3rem 4.5rem rgba(103, 179, 249, 0.3));
        }
      }
      .signin-submit-button {
        width: 51.7rem;
        height: 7.8rem;
        background: #11283c;
        box-shadow: 0 0.4rem 4.5rem rgba(103, 179, 249, 0.1);
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 3rem;
        cursor: pointer;
        transform: scale(1);
        transition: all 0.2s;

        .button-signin-text {
          font-family: "Switzer", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 2.6rem;
          color: #ffffff;
        }

        &:hover {
          transform: scale(1.01);
          box-shadow: 0 0.4rem 5.5rem rgba(103, 179, 249, 0.3);
        }
        &:active {
          transform: scale(1);
          box-shadow: 0 0.4rem 4.5rem rgba(103, 179, 249, 0.1);
        }
      }
    }
  }
}
