@font-face {
  font-family: "Newake";
  src: url(./Assets/fonts/indieground-newakefont-demo/Newake-Font-Demo.otf);
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: "Pangrams";
  src: url(./Assets/fonts/PangramSans-FreeForPersonalUse/Pangram-FullFamily-FreeForPersonalUse/Pangram-Regular.otf);
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Pangrams";
  src: url(./Assets/fonts/PangramSans-FreeForPersonalUse/Pangram-FullFamily-FreeForPersonalUse/Pangram-Light.otf);
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Pangrams";
  src: url(./Assets/fonts/PangramSans-FreeForPersonalUse/Pangram-FullFamily-FreeForPersonalUse/Pangram-Bold.otf);
  font-style: normal;
  font-weight: 700;
}
:root {
  --dataviewer-height: 12.1rem;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Pangrams", sans-serif;
  font-style: normal;
  font-weight: 400;
}
