.data-viewer_container {
  width: 100%;
  height: var(--dataviewer-height);
  background-color: #fff;
  box-shadow: 0px 0.4rem 4.5rem rgba(0, 0, 0, 0.07);
  border-radius: 3rem;
  display: flex;
  flex-direction: column;

  .data-viewer_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.02rem solid #ececec;
    &-title {
      margin-left: 2.5rem;
      font-family: "Newake";
      font-size: 3rem;
      font-weight: bold;

      padding: 2.5rem;
    }
  }
  .data-viewer_list {
    height: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    &-items {
      padding: 1rem 5rem;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      flex: 0 0 25%;

      text-align: left;
      &:not(:last-child) {
        border-bottom: 0.02rem solid #ececec;
      }
      .data-viewer_list-item_header {
        font-weight: 800;
        font-family: "Pangram";
        font-size: 1.7rem;
      }
      .data-viewer_list-item {
        font-family: "Pangram";
        font-size: 1.7rem;
        font-weight: 400;
      }
      .data-viewer_list-action {
        &_button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          &:not(:last-child) {
            margin-right: 1rem;
          }
        }
      }
    }
  }
  .data-viewer_no-data {
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 1rem;
    font-family: "Pangram";
    font-size: 2rem;
    font-weight: 400;
  }
}
