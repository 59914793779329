.raw-mat-info {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  &_header {
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-left: 3rem;
    margin-bottom: 3rem;
    &-head {
      font-family: "Newake";
      font-size: 3rem;
    }
  }
  &_data_container {
    margin-left: 3rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .raw-mat-info_data {
      width: 95%;
      padding: 3rem;
      margin-bottom: 1rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;

      background-color: white;
      box-shadow: 0px 0.4rem 2.5rem rgba(0, 0, 0, 0.07);
      justify-content: space-between;

      &_header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 1rem;
        &-head {
          font-size: 5rem;
          font-weight: bold;
        }
      }
      &_items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &-item {
          font-size: 2rem;
        }
      }
    }
  }
}
