.stock_container {
  width: 100%;
  height: 100%;
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
  .stock_breadcrumbs {
    margin: 1.5rem 3rem;
    font-family: "Pangram";
    font-size: 2rem;
    font-weight: 400;
    color: #11283c;
  }
  .stock_list {
    margin: 1.5rem 3rem;
    width: 151.6rem;
    height: 110%;
  }
  .stock_pagination {
    padding: 1.5rem 3rem;
    width: 151.6rem;
    height: 10rem;
    font-size: 2rem;
  }
}
