.add-data-form {
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;

  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  &_container {
    position: relative;

    width: 75%;
    padding: 3rem 3rem 6rem 3rem;

    background-color: aliceblue;
    border-radius: 3rem;

    .add-data-form_header_close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      font-size: 2rem;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }

    .add-data-form_header {
      font-family: "Newake";
      font-size: 2.8rem;
      margin: 1.5rem 3rem 8rem 3rem;
    }
    .add-data-form_form {
      &-form {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        &_container {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin-left: 7rem;
          margin-bottom: 3rem;
          &-item {
            width: 45%;
            margin-left: 3rem;
            position: relative;

            &:not(:last-child) {
              margin-bottom: 3rem;
            }
            &-label {
              position: absolute;
              font-family: "Pangram";
              font-size: 2.5rem;
              font-weight: 800;
              transform: translateY(-2rem);
            }

            &-field {
              width: 100%;
              height: 7rem;
              border: none;
              padding: 2rem;
              border-radius: 0.5rem;
              box-shadow: 0 0.4rem 4.5rem rgba(103, 179, 249, 0.1);

              font-family: "Pangram";
              font-size: 1.7rem;

              &:focus {
                transform: translateY(-0.1rem), scale(1.01);
                outline: none;
                border-bottom: 0.2rem solid #1b1c1e;
              }
            }
          }
        }
        &-submit {
          &-button {
            width: 58.7rem;
            height: 7rem;
            background: #11283c;
            box-shadow: 0 0.4rem 4.5rem rgba(103, 179, 249, 0.1);
            border-radius: 1rem;
            border: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-left: 10rem;
            margin-bottom: 1.5rem;
            cursor: pointer;
            transform: scale(1);
            transition: all 0.2s;

            font-family: "Switzer", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 2rem;
            color: #ffffff;

            &:hover {
              transform: scale(1.01);
              box-shadow: 0 0.4rem 5.5rem rgba(103, 179, 249, 0.3);
            }
            &:active {
              transform: scale(1);
              box-shadow: 0 0.4rem 4.5rem rgba(103, 179, 249, 0.1);
            }
          }
        }
      }
    }
  }
}
