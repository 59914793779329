.button-add-item {
  font-family: "Pangram";
  font-size: 2rem;
  font-weight: 400;
  margin-right: 5rem;
  width: 15%;
  height: 5rem;
  background-color: #11283c;
  color: #fff;
  border-radius: 1.6rem;
  border: none;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    transform: scale(1.01);
  }
  &:active {
    transform: scale(1);
  }
}
